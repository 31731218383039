<template>
    <div class="w">
        <!-- layout栅格 设置多少行多少列 span是宽度 gutter是间隙 -->
        <el-row :gutter="3" style="margin-bottom: 30px;">
            <el-col :span="7">
                <div class="block">
                    <span class="demonstration">检查时间：</span>
                    <el-date-picker v-model="timeObj" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" @change="changeDate">
                    </el-date-picker>
                </div>
            </el-col>
            <el-col :span="4">
                <div class="block">
                    <span class="demonstration">学校：</span>
                    <el-select v-model="queryData.schoolId" placeholder="请选择" clearable>
                        <el-option v-for="item in shoolList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
            <el-col :span="2"><el-button type="primary" @click="getGradeStatistics()">开始统计</el-button></el-col>

        </el-row>
        <div class="ecMain">
            <div>
                <span class="demonstration">性别：</span>
                <div>
                    <el-radio-group v-model="queryData.sex" @input="changeSex">
                        <el-radio label="2">全部</el-radio>
                        <el-radio label="1">男性</el-radio>
                        <el-radio label="0">女性</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div id="GradeBox" style="width: 1000px;height:600px;"></div>
        </div>

    </div>
</template>

<script>
import * as echarts from 'echarts';
import { forEach } from 'lodash';
import {getGradeName} from '@/utls/common.js';
export default {
    data() {
        return {
            timeObj: null,
            shoolList: [],
            queryData: {
                schoolId: 0,
                startTime: "",
                endTime: "",
                sex: '2',
            }
        }
    },
    mounted() {
        this.getGradeStatistics();
        this.getShoolList();
    },
    methods: {
        changeSex(v) {
            console.log(v);
            this.queryData.sex = v;
            this.getGradeStatistics();
        },
        getGradeStatistics() {
            let that = this;
            this.$http.get('/statistics/getGradeStatistics', {
                params: this.queryData
            }).then((res) => {
                let data = res.data;
                if (data.code != 1) {
                    return that.$message.error('加载失败')
                }
                if (data.data.length == 0) {
                    that.$message.error('无数据')
                }
                let xAxisData = [];
                let yData = [];
                for (let index = 0; index < 8; index++) {
                    yData.push([])

                }
                data.data.forEach((v, i) => {


                    let name = getGradeName(v.gradeId);
                    xAxisData.push(name);
                    let rawArr = v.footConditions;
                    const zc = rawArr.find(item => item.archType === "正常");
                    yData[0].push(zc ? zc.conditionCount : 0)
                    const qg = rawArr.find(item => item.archType === "轻度高弓");
                    yData[1].push(qg ? qg.conditionCount : 0)
                    const zhg = rawArr.find(item => item.archType === "中度高弓");
                    yData[2].push(zhg ? zhg.conditionCount : 0)
                    const zg = rawArr.find(item => item.archType === "重度高弓");
                    yData[3].push(zg ? zg.conditionCount : 0)
                    const qb = rawArr.find(item => item.archType === "轻度扁平");
                    yData[4].push(qb ? qb.conditionCount : 0)
                    const zhb = rawArr.find(item => item.archType === "中度扁平");
                    yData[5].push(zhb ? zhb.conditionCount : 0)
                    const zb = rawArr.find(item => item.archType === "重度扁平");
                    yData[6].push(zb ? zb.conditionCount : 0)
                    const wz = rawArr.find(item => item.archType === "未知");
                    yData[7].push(wz ? wz.conditionCount : 0)
                });



                that.init(xAxisData, yData);
            })

        },

init(xAxisData, yData) {
    let chartDom = document.getElementById('GradeBox');
    let myChart = echarts.init(chartDom);
    let option;
    let rawData = yData;
    const totalData = [];
    for (let i = 0; i < rawData[0].length; ++i) {
        let sum = 0;
        for (let j = 0; j < rawData.length; ++j) {
            sum += rawData[j][i];
        }
        totalData.push(sum);
    }
    const grid = {
        left: 100,
        right: 100,
        top: 50,
        bottom: 50
    };
    const series = [
        '正常',
        '轻度高弓',
        '中度高弓',
        '重度高弓',
        '轻度扁平',
        '中度扁平',
        '重度扁平',
        '未知',
    ].map((name, sid) => {
  
        let color;
                switch (name) {
                    case '重度高弓':
                        color = '#ffff00';
                        break;
                    case '中度高弓':
                        color = '#2e75b6';
                        break;
                    case '轻度高弓':
                        color = '#bdd7ee';
                        break;
                    case '正常':
                        color = '#2E8B57';
                        break;
                    case '轻度扁平':
                        color = '#fbe5d6';
                        break;
                    case '中度扁平':
                        color = '#f4b488';
                        break;
                    case '重度扁平':
                        color = '#FF0000';
                        break;
                    case '未知':
                        color = '#DCDCDC';
                        break;
                }
        return {
            name,
            type: 'bar',
            stack: 'total',
            barWidth: '60%',
            label: {
                show: true,
                formatter: (params) => {
                    const value = params.value;
                    const total = totalData[params.dataIndex];
                    return `${(value * 100).toFixed(2)}%`;
                }
            },
            data: rawData[sid].map((d, did) =>
                totalData[did] <= 0 ? 0 : d / totalData[did]
            ),
            color // 设置颜色
        };
    });
    option = {
        legend: {
            selectedMode: true,
            data: series.map(item => item.name)
        },
        grid,
        yAxis: {
            type: 'value'
        },
        xAxis: {
            type: 'category',
            data: xAxisData
        },
        series,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter: function (params) {
                let tooltipContent = params[0].name + '<br>';
                params.forEach(param => {
                    const dataIndex = param.dataIndex;
                    const value = param.value;
                    const total = totalData[dataIndex];
                    const quantity = rawData[param.seriesIndex][dataIndex];
                    tooltipContent += `${param.seriesName} : ${quantity}, 概率: ${(value * 100).toFixed(2)}%<br>`;
                });
                return tooltipContent;
            }
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {
                    show: true,
                    title: '保存为图片',
                    type: 'png',
                    lang: ['点击保存'] // 可以自定义按钮上的文字
                }
            }
        }
    };
    myChart.setOption(option);

    // 监听 legend 点击事件
    myChart.on('legendselectchanged', function (event) {
        let selected = event.selected;
        let seriesName = Object.keys(selected)[0];
        let newOption = {
            series: series.map(item => {
                if (item.name === seriesName) {
                    item.selected = selected[seriesName];
                }
                return item;
            })
        };
        myChart.setOption(newOption);
    });
},


        changeDate(e) {
            this.queryData.startTime = e[0];
            this.queryData.endTime = e[1];
            this.getGradeStatistics();
        },
        //获取学校列表
        async getShoolList() {
            this.$http.get("/Report/getSchoolList").then((res) => {

                let data = res.data;
                if (data.code == 1) {
                    this.shoolList = data.data;
                }

            })

        },
    }
}
</script>

<style lang="less" scoped>
.ecMain {
    border: 6px solid #ccc;
    width: 1000px;
    border-radius: 20px;

    >div:nth-child(1) {
        display: flex;

        margin: 10px 0 10px 380px;
    }
}
</style>