<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>报表统计</el-breadcrumb-item>
      <el-breadcrumb-item>统计报表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-tabs tabPosition="left" type="card">
        <el-tab-pane label="用户管理">
          <div style="height: 40px;font-size: 20px;">用户管理</div>
          <el-row :gutter="20">
            <el-col :span="5">
              <div class="grid-content bg-purple">学年：
                <el-select v-model="value" filterable placeholder="请选择" size="30px" clearable>
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">学年：
                <el-select v-model="value" filterable placeholder="请选择" size="30px" clearable>
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple"><el-button type="primary">查询</el-button> <el-button>重置</el-button></div>
            </el-col>
          </el-row>
          <!-- table表格区域 -->
          <el-table :data="userlist" border stripe>
            <el-table-column type="index"></el-table-column>
            <el-table-column label="" prop="name"></el-table-column>
            <el-table-column label="" prop="positive"></el-table-column>
            <el-table-column label="" prop="total"></el-table-column>
            <!-- <el-table-column label="正常" prop="normal"></el-table-column>
            <el-table-column label="阳性人数" prop="positive"></el-table-column>
            <el-table-column label="10岁阳性" prop="positiveAge10"></el-table-column>
            <el-table-column label="11岁阳性" prop="positiveAge11"></el-table-column>
            <el-table-column label="12岁阳性" prop="positiveAge12"></el-table-column>
            <el-table-column label="13岁阳性" prop="positiveAge13"></el-table-column>
            <el-table-column label="6岁阳性" prop="positiveAge6"></el-table-column>
            <el-table-column label="7岁阳性" prop="positiveAge7"></el-table-column>
            <el-table-column label="8岁阳性" prop="positiveAge8"></el-table-column>
            <el-table-column label="9岁阳性" prop="positiveAge9"></el-table-column>
            <el-table-column label="女阳性人数" prop="positiveGirl"></el-table-column>
            <el-table-column label="男阳性人数" prop="positiveMan"></el-table-column>
            <el-table-column label="重度扁平" prop="severeFlatfoot"></el-table-column>
            <el-table-column label="重度高弓" prop="severeHighArch"></el-table-column> -->
          </el-table>
          <!-- 分页区域 -->
          <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="queryInfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
                    </el-pagination> -->
        </el-tab-pane>
        <el-tab-pane label="配置管理">配置管理</el-tab-pane>
        <el-tab-pane label="角色管理">角色管理</el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      userlist: [],
      queryInfo: {
      },
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: ''
    }
  },
  created() {
    this.getPositiveStatistics();
  },
  methods: {
    //获取列表
    async getPositiveStatistics() {
      const { data: res } = await this.$http.get('/statistics/getPositiveStatistics', {
        params: this.queryInfo
      })
      if (res.code != 1) {
        return this.$message.error('获取列表失败！')
      }
      this.userlist = res.data;
    },
  }
}
</script>
  
<style lang="less" scoped></style>
  