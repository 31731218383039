<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>设备列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入内容" v-model="queryInfo.query" clearable>
            <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true">添加设备</el-button>
          <!-- 点击后跳转到路由/goods/add还是这个页面位置显示 -->
        </el-col>
      </el-row>
      <!-- 添加诊所的对话框 -->
      <el-dialog title="添加诊所" :visible.sync="addDialogVisible" @close="closeDialog" width="40%">
        <el-form :model="addForm" :rules="editFormRules" ref="addFormRef" label-width="80px">
          <el-form-item label="详情地址" prop="address" label-width="80px">
            <el-input v-model="addForm.address"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="mobile" label-width="80px">
            <el-input v-model="addForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="店铺电话" prop="storePhone" label-width="80px">
            <el-input v-model="addForm.storePhone"></el-input>
          </el-form-item>
          <el-form-item label="上班时间" prop="workingHours" label-width="80px">
            <el-input v-model="addForm.workingHours"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="linkman" label-width="80px">
            <el-input v-model="addForm.linkman"></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addUser">确 定</el-button>
        </span>
      </el-dialog>

      <!-- table表格区域 -->
      <el-table :data="goodslist" border stripe>

        <el-table-column type="index"></el-table-column>
        <el-table-column label="店铺名称" prop="name"></el-table-column>
        <el-table-column label="手机号码" prop="mobile"></el-table-column>
        <el-table-column label="店铺电话" prop="storePhone"></el-table-column>
        <el-table-column label="创建人" prop="createBy"></el-table-column>
        <el-table-column label="更新人" prop="updateBy"></el-table-column>
        <el-table-column label="上班时间" prop="workingHours"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope"> <!-- 作用域插槽 -->
            <!-- 修改按钮 -->
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="amend(scope.row.id)"></el-button>
            <!-- 把对应的id传过去发起相应的数据请求 -->
            <!-- 删除按钮 -->
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeUserById(scope.row.id)"></el-button>
            <!-- 分配角色按钮 -->
            <!-- <el-tooltip effect="dark" content="分配角色" placement="top" :enterable="false"> 
                <el-button type="warning" icon="el-icon-setting" size="mini" @click="setRole(scope.row)"></el-button>
              </el-tooltip> -->
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
        :page-sizes="[10, 20, 50, 100, 200, 500]" :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>

    </el-card>
  </div>
</template>
  
<script>
import { regionData, CodeToText } from "element-china-area-data";
export default {
  data() {


    return {
      // 查询参数对象
      queryInfo: {
        query: '',
        page: 1,
        pageSize: 10,
        state: 1
      },
      addForm: {
        selectedOptions: '',
        address: '',
        mobile: '',
        storePhone: '',
        workingHours: '',
        linkman: '',
        name: '',
        province: '',
        city: '',
        area: ''
      },
      amendFrom: {

      },
      addDialogVisible: false,
      // 商品列表
      goodslist: [],
      // 总数据条数
      total: 0,
    }
  },
  created() {
    this.getUserList()
  },
  methods: {
    closeDialog() {
      this.addForm = {
        selectedOptions: '',
        address: '',
        mobile: '',
        storePhone: '',
        workingHours: '',
        linkman: '',
        name: '',
        province: '',
        city: '',
        area: ''
      }
    },
    amend(data) {
      this.addForm = JSON.parse(JSON.stringify(data))
      this.addDialogVisible = true;
    },
    // ----------删除设备
    async removeUserById(id) {
      // 弹框询问用户是否删除数据（参见element）
      const confirmResult = await this.$confirm( /* 先给vue挂载了$confirm函数 里面的参数代表弹框显示的内容样式 函数的返回值是promise 所以可以用asyc和await来优化 这样返回的值即confirmResult就是一个字符串了（之前的是一个数据）如果确定就是confirm 取消就是cancle 由catch捕获 */
        '此操作将永久删除该用户, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err) /* 点取消则由catch捕获异常 return err的简写 把err返回 */

      // 如果用户确认删除，则返回值为字符串 confirm
      // 如果用户取消了删除，则返回值为字符串 cancel
      // console.log(confirmResult)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }

      // 确认删除 先发送请求 判断删除是否成功 不用传id参数
      const { data: res } = await this.$http.get('/Clinic/delete', {
        params: {
          id: id
        }
      })

      if (res.code != 1) {
        return this.$message.error('删除用户失败！')
      }

      this.$message.success('删除用户成功！');
      this.getUserList()
    },
    // 根据分页获取对应的设备列表
    async getUserList() {
      const { data: res } = await this.$http.get('/Clinic/getClinicList', {
        params: this.queryInfo
      })

      if (res.code != 1) {
        return this.$message.error('获取设备列表失败！')
      }
      this.$message.success('获取设备列表成功！')
      console.log(res.data)
      this.goodslist = res.data.records
      this.total = res.data.total
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getUserList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getUserList()
    },
    // 点击确定，设备进行预校验
    addUser() {
      console.log(this.addForm);
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        // 校验通过 发起添加用户的网络请求
        const { data: res } = await this.$http.post(this.addForm.id ? '/Clinic/amend' : '/Clinic/addClinic', this.addForm)
        // 请求的返回结果
        if (res.code != 1) return this.$message.error('添加诊所失败！')
        this.$message.success('添加诊所成功！')
        // 隐藏添加用户的对话框
        this.addDialogVisible = false
        // 重新获取用户列表数据（因为有绑定了 所以会自动渲染出来）
        this.getUserList()
      })
    },
    async removeById(id) {
      const confirmResult = await this.$confirm(
        '此操作将永久删除该设备, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)

      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }

      const { data: res } = await this.$http.get(``, {
        params: { id: id }
      })

      if (res.code != 1) {
        return this.$message.error('删除失败！')
      }

      this.$message.success('删除成功！')
      this.getGoodsList()
    },

  }
}
</script>
  
<style lang="less" scoped></style>
  