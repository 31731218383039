<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="7">
          <div class="block">
            <span class="demonstration">检查时间：</span>
            <el-date-picker v-model="timeObj" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="7">
          <el-input placeholder="请输入内容" v-model="queryInfo.name" clearable>
          </el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="queryInfo.payState" placeholder="请选择">
            <el-option key="1" label="已付款" value="1">
            </el-option>
            <el-option key="0" label="未付款" value="0">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1"><el-button @click="getOrderList" icon="el-icon-search">搜索</el-button></el-col>
      </el-row>

      <!-- 订单列表数据 -->
      <el-table :data="orderlist" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="操作" prop="name" width="50">
          <template slot-scope="scope">
            <i class="el-icon-view" @click="getArgumentsByOrderId(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column label="收货人" prop="consignee"></el-table-column>
        <el-table-column label="收货地址" prop="address"></el-table-column>
        <el-table-column label="手机号码" prop="phone" width="100px"></el-table-column>
        <el-table-column label="付款金额" prop="amount"></el-table-column>
        <el-table-column label="商品名称" prop="commodityName" width="260px"></el-table-column>
        <el-table-column label="复诊单" prop="scope" width="90">
          <template slot-scope="scope">
            <!-- 作用域插槽 新版是v-slot="scope" scope.row代表这一行的所有数据 这是从上面userlist里拿出来的 -->
            <img style="height: 30px" @click="EnlargeImage(item)" v-for="(item, index) in scope.row.reportUrlList "
              :key="index" :src="item" />
          </template>
        </el-table-column>
        <el-table-column label="款式" prop="commodityColorName" width="160px"></el-table-column>
        <el-table-column label="快递信息" prop="courierNumber" width="160px"></el-table-column>
        <el-table-column label="鞋盒快递信息" prop="courierNumber" width="160px"></el-table-column>
        <el-table-column label="创建订单时间" prop="createTime" width="160px"></el-table-column>
        <el-table-column label="原价" prop="originalPrice"></el-table-column>
        <el-table-column label="买家备注" prop="userRemark"></el-table-column>
        <el-table-column label="是否付款" prop="pay_status">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.payState == '1'">已付款</el-tag>
            <el-tag type="danger" v-else>{{ scope.row.payState == '0' ? "未付款" : scope.row.payState ==
              '2' ? "退款中" : scope.row.payState == '3' ? "已退款" : "拒接退款" }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="报告id" prop="reportId">

        </el-table-column>
        <el-table-column label="是否审核" prop="pay_status" width="110px">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.doctorCheckState == '1'">审核通过</el-tag>
            <el-tag type="danger" v-else>{{ scope.row.doctorCheckState == '2' ? "未审核" : "不通过" }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="是否发货" prop="is_send">
          <template slot-scope="scope">
            <template>
              {{ scope.row.is_send }}
            </template>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300px">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="showBox(scope.row)"></el-button>
            <el-button size="mini" type="success" icon="el-icon-upload2" @click="showForward(scope.row)"></el-button>
            <!-- <el-button size="mini" type="warning " icon="el-icon-close" @click="refund(scope.row)"></el-button> -->
            <el-button size="mini" type="info" icon="el-icon-plus" @click="LogisticsInformation(scope.row)"></el-button>
            <el-button size="mini" type="primary" icon="el-icon-share" @click="ViewReport(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 用户体检次数抽屉 -->
      <el-drawer title="用户次数选择" :visible.sync="drawer" :with-header="false" size="50%">
        <div style="height: 70px;align-items: center;display: flex;">
          <span class="demonstration">报告编号：</span>
          <el-row :gutter="40">
            <el-col :span="20"><el-input v-model="reportId" placeholder="请输入报告编号"></el-input></el-col>
            <el-col :span="1"><el-button type="primary" icon="el-icon-search"
                @click="getReportByReportId(reportId)">搜索</el-button></el-col>
          </el-row>
        </div>



        <div
          style="border: #e6e6e6 solid 2px;width: 95%;margin: 0 auto;display: flex;align-items: center; margin-top: 20px;"
          @click="getReportByReportId(item.reportId)" v-for="(item, index) in NumberTimes" :key="index">
          <div class="NumberOfTimes" style="width: 90%;">
            <div>用户姓名：{{ item.name }}</div>
            <div>足弓类型：{{ item.archType }}</div>
            <div>扫描时间: {{ item.scanTime }}</div>
          </div>
          <div style="font-size: 34px;"><i class="el-icon-arrow-right"></i></div>

        </div>
      </el-drawer>



      <!-- 足部报告 -->
      <div>
        <el-drawer title="足部报告" :append-to-body="true" :visible.sync="innerDrawer">
          <button @click="reportMatching()" type="primary">报告匹配保存</button>
          <span>
            <div style="width: 95%;margin: 0 auto;">
              <div class="nameage">

                <div class="title" style="">基本信息</div>
                <div style="width: 95%;margin: 20px 17px;">
                  <el-row :gutter="20">
                    <el-col :span="10">
                      <div class="grid-content bg-purple">姓名：{{ FootReport.userName }}</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content bg-purple">年龄：{{ FootReport.age }}</div>
                    </el-col>
                    <el-col :span="6">
                      <div class="grid-content bg-purple">性别：{{ FootReport.sex == '0' ? "女" : "男" }}</div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="images">
                <div class="title" style="">图片信息</div>
                <el-row :gutter="20">
                  <el-col :span="6" style="width: 50%; border-right: #e6e6e6 solid 1px;">
                    <div class="grid-content"><img :src="FootReport.imgL" alt=""></div>
                  </el-col>
                  <el-col :span="6" style="width: 50%;">
                    <div class="grid-content"><img :src="FootReport.imgR" alt=""></div>
                  </el-col>
                </el-row>
              </div>
              <div class="FootData">
                <div class="title" style="">足部数据</div>
                <div class="FootData-text">
                  <el-row :gutter="20">
                    <el-col :span="6" style="width: 50%; border-right: #e6e6e6 solid 1px;">
                      <div style="text-align: center;">左脚足部数据</div>
                      <div>足弓高度:{{ FootReport.archHgtL }}</div>
                      <div>脚型:{{ FootReport.footTypeL }}</div>
                      <div>足印面积系数:{{ FootReport.archAreaIndexL }}</div>
                      <div>足宽面积系数:{{ FootReport.archWidthIndexL }}</div>
                      <div>拇指外翻角度:{{ FootReport.thumbAngleL }}°</div>
                      <div>大拇指高度:{{ FootReport.thumbHgtL }}°</div>
                      <div>扁平足:{{ FootReport.archPattern }}</div>
                      <div>拇指外翻: {{ FootReport.thumbPattern }}</div>
                    </el-col>
                    <el-col :span="6" style="width: 50%; ">
                      <div style="text-align: center;">右脚足部数据</div>
                      <div>足弓高度:{{ FootReport.archHgtR }}</div>
                      <div>脚型:{{ FootReport.footTypeL }}</div>
                      <div>足印面积系数:{{ FootReport.archWidthIndexR }}</div>
                      <div>足宽面积系数:{{ FootReport.archWidthIndexR }}</div>
                      <div>拇指外翻角度:{{ FootReport.thumbAngleR }}°</div>
                      <div>大拇指高度:{{ FootReport.thumbHgtR }}°</div>
                      <div>扁平足:{{ FootReport.archPattern }}</div>
                      <div>拇指外翻: {{ FootReport.thumbPattern }}</div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="FootData">
                <div class="title" style="">鞋垫参数</div>
                <div class="FootData-text">
                  <el-row :gutter="20">
                    <el-col :span="6" style="width: 50%; border-right: #e6e6e6 solid 1px;">
                      <div style="text-align: center;">左脚足部数据</div>
                      <div>鞋垫长度: <el-input v-model="report.insoleLengthL" style="width:100px;height: 50px;"></el-input>
                      </div>
                      <div>内足弓矫正: <el-input v-model="report.archCorrectL" style="width:100px ;height: 50px;"></el-input>
                      </div>
                      <div>鞋垫宽度: <el-input v-model="report.insoleWidthL" style="width:100px;height: 50px;"></el-input>
                      </div>
                      <div>足跟宽度矫正: <el-input v-model="report.heelWidthL" style="width:100px;height: 50px;"></el-input>
                      </div>
                      <div>足跟环高度: <el-input v-model="report.heelLoopHeightL" style="width:100px;height: 50px;"></el-input>
                      </div>
                      <div>拇指外翻矫正: <el-checkbox v-model="report.thumbCorrectL"></el-checkbox></div>
                      <div>足弓骨垫: <el-checkbox v-model="report.archBonePadL"></el-checkbox></div>
                      <div>足跟内翻矫正: <el-checkbox v-model="report.heelVarusCorrectL"></el-checkbox></div>
                      <div>足跟外翻矫正: <el-checkbox v-model="report.heelEctropionCorrectL"></el-checkbox></div>
                    </el-col>
                    <el-col :span="6" style="width: 50%; ">
                      <div style="text-align: center;">右脚足部数据</div>
                      <div>鞋垫长度: <el-input v-model="report.insoleLengthR" style="width:100px;height: 50px;"></el-input>
                      </div>
                      <div>内足弓矫正: <el-input v-model="report.archCorrectR" style="width:100px ;height: 50px;"></el-input>
                      </div>
                      <div>鞋垫宽度: <el-input v-model="report.insoleWidthR" style="width:100px;height: 50px;"></el-input>
                      </div>
                      <div>足跟宽度矫正: <el-input v-model="report.heelWidthR" style="width:100px;height: 50px;"></el-input>
                      </div>
                      <div>足跟环高度: <el-input v-model="report.heelLoopHeightR" style="width:100px;height: 50px;"></el-input>
                      </div>
                      <div>拇指外翻矫正: <el-checkbox v-model="report.thumbCorrectR"></el-checkbox></div>
                      <div>足弓骨垫: <el-checkbox v-model="report.archBonePadR"></el-checkbox></div>
                      <div>足跟内翻矫正: <el-checkbox v-model="report.heelVarusCorrectR"></el-checkbox></div>
                      <div>足跟外翻矫正: <el-checkbox v-model="report.heelEctropionCorrectR"></el-checkbox></div>
                    </el-col>
                  </el-row>
                  <el-col :span="6" style="height: 100px;align-items: center;display: flex;">
                    <div><el-button type="primary" @click="saveArguments">鞋垫数据保存</el-button></div>
                  </el-col>
                </div>
              </div>
            </div>
          </span>
        </el-drawer>
      </div>

      <!--放大图片 -->
      <el-dialog title="复诊单图片" :visible.sync="Enlarge" width="40%">
        <img style="width: 100%;" :src="reportUrl" alt="">
      </el-dialog>
      <!--退款确认对话框 -->
      <el-dialog title="退款信息确认" :visible.sync="RefundDialogBox" width="40%">
        <el-form :model="addForm" ref="addFormRef" label-width="50px">
          <div>退款金额：<el-input v-model="money" placeholder="请输入内容"></el-input></div>
          <div>管理员留言：<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="administratorRemark"></el-input>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="RefundDialogBox = false">取 消</el-button>
          <el-button type="primary" @click="refundDisposeByOrderId">确 定</el-button>
        </span>
      </el-dialog>

      <!--添加物流信息 -->
      <el-dialog title="添加物流信息" :visible.sync="logistics" width="40%">
        <el-form :model="addForm" ref="addFormRef" label-width="100px">
          <el-form-item label="选择快递公司">
            <el-select v-model="coding" placeholder="请选择">
              <el-option v-for="item in CourierList" :key="item.coding" :label="item.name" :value="item.coding">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择快递物品">
            <el-select v-model="type" placeholder="请选择">
              <el-option label="商品订单快递" :value="1"> </el-option>
              <el-option label="模盒快递单号" :value="2">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="快递单号：">
            <div><el-input v-model="CourierNumber" placeholder="请输入内容"></el-input></div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="logistics = false">取 消</el-button>
          <el-button type="primary" @click="LogisticsConfirmation">确 定</el-button>
        </span>
      </el-dialog>   
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
        :page-sizes="[10, 20, 50, 100, 200, 500]" :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>

    <!-- 修改地址的对话框 -->
    <el-dialog title="修改地址" :visible.sync="addressVisible" width="50%">
      <el-form :model="addressForm" :rules="addressFormRules" ref="addressFormRef" label-width="120px">
        <el-form-item label="收货人姓名">
          <el-input v-model="addressForm.consignee"></el-input>
        </el-form-item>
        <el-form-item label="收货人手机号" prop="phone">
          <el-input v-model="addressForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="详细地址" prop="shippingAddress">
          <el-input v-model="addressForm.shippingAddress"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addressVisible = false">取 消</el-button>
        <el-button type="primary" @click="ModifyAddress">确 定</el-button>
      </span>
    </el-dialog>

    <!--分配订单给医生的对话框 -->
    <el-dialog title="分配给医生订单" :visible.sync="addDialogVisible" width="40%">
      <el-form :model="addForm" ref="addFormRef" label-width="50px">
        <el-form-item label="分配订单" prop="userId" label-width="80px">
          <el-input @change="QueryDoctor" v-model="QueryDoctorValue" placeholder="请输入内容"></el-input>
        </el-form-item>

        <el-form-item label="选择" label-width="80px">
          <div class="DoctorList">
            <div class="DoctorItem" :style="item.userId == forwardForm.doctorId ? 'background-color: aqua;' : ''"
              @click="selectDoctor(item)" v-for="(item, index) in DoctorList" :key="index">{{ item.name }}-{{
                item.hospitalName }}</div>
          </div>
        </el-form-item>
        <!-- <el-form-item label="设备名称、编号" prop="name" label-width="180px">
              <el-input v-model="addForm.name"></el-input>
            </el-form-item> -->
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determineForward">确 定</el-button>
      </span>

    </el-dialog>

  </div>
</template>

<script>
import cityData from './citydata.js'

export default {
  data() {
    return {

      innerDrawer: false,
      drawer: false,
      Enlarge: false,
      orderId: '',
      reportId: '',
      reportUrl: "",
      timeObj: "",
      CourierList: [],
      addDialogVisible: false,
      RefundDialogBox: false,
      logistics: false,
      CourierNumber: '',
      coding: '',
      RefundNote: '',
      administratorRemark: "",
      money: 0,
      addForm: {
      },
      forwardForm: {
        id: 0,
        doctorId: 0,
      },
      QueryDoctorValue: "",
      queryInfo: {
        page: 1,
        pageSize: 10,
        startTime: '',
        endTime: '',
        name: '',
        payState: '',
        state: ''
      },
      DoctorList: [],
      total: 0,
      orderlist: [],
      doctorIds: '',
      type: 1,
      addressVisible: false,
      addressForm: {
        consignee: '',
        phone: '',
        shippingAddress: '',

      },
      addressFormRules: {
        phone: [
          //手机检验
          { required: true, message: '手机号码', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
        ],
        shippingAddress: [
          { required: true, message: '详细地址', trigger: 'blur' },
        ]
      },
      cityData,
      progressVisible: [],
      progressInfo: [],
      NumberTimes: [],
      FootReport: {},
      report: {
        orderId: '',
        archBonePadL: false,
        archBonePadR: false,
        archCorrectL: 0,
        archCorrectR: 0,
        createBy: "",
        createTime: "",
        heelEctropionCorrectL: false,
        heelEctropionCorrectR: false,
        heelLoopHeightL: 0,
        heelLoopHeightR: 0,
        heelVarusCorrectL: false,
        heelVarusCorrectR: false,
        heelWidthL: 0,
        heelWidthR: 0,
        thumbCorrectL: false,
        thumbCorrectR: false,
        insoleLengthL: 0,
        insoleLengthR: 0,
        insoleWidthL: 0,
        insoleWidthR: 0,

      },
    }
  },
  created() {
    this.getOrderList();
    this.getCourierCodingList();
  },
  methods: {
    // 保存鞋垫参数
    async saveArguments() {
      console.log(this.report);
      let from = JSON.parse(JSON.stringify(this.report));
      //保存用户选择的多选框是true还是false
      from.archBonePadL = from.archBonePadL == true ? 1 : 0;
      from.archBonePadR = from.archBonePadR == true ? 1 : 0;
      from.heelEctropionCorrectL = from.heelEctropionCorrectL == true ? 1 : 0;
      from.heelEctropionCorrectR = from.heelEctropionCorrectR == true ? 1 : 0;
      from.heelVarusCorrectL = from.heelVarusCorrectL == true ? 1 : 0;
      from.heelVarusCorrectR = from.heelVarusCorrectR == true ? 1 : 0;
      from.thumbCorrectL = from.thumbCorrectL == true ? 1 : 0;
      from.thumbCorrectR = from.thumbCorrectR == true ? 1 : 0;
      console.log(from);
      const { data: res } = await this.$http.post('/orderArguments/saveArguments', from)
      if (res.code != 1) return this.$message.error(res.msg)
      this.$message.success('保存成功！')
      console.log(res);
    },

    //// 根据订单id获取鞋垫参数
    async getArgumentsByOrderId(item) {
      const { data: res } = await this.$http.get('/orderArguments/getArgumentsByOrderId', {
        params: {
          orderId: item.id,
        }
      })
      this.getReportByReportId(item.reportId);
      if (res.code == 1) {
        if (res.data != null) {
          this.innerDrawer = true;

          //转换格式
          res.data.thumbCorrectL = res.data.thumbCorrectL == 1 ? true : false;
          res.data.thumbCorrectR = res.data.thumbCorrectR == 1 ? true : false;
          res.data.heelVarusCorrectL = res.data.heelVarusCorrectL == 1 ? true : false;
          res.data.heelVarusCorrectR = res.data.heelVarusCorrectR == 1 ? true : false;
          res.data.heelEctropionCorrectL = res.data.heelEctropionCorrectL == 1 ? true : false;
          res.data.heelEctropionCorrectR = res.data.heelEctropionCorrectR == 1 ? true : false;
          res.data.archBonePadL = res.data.archBonePadL == 1 ? true : false;
          res.data.archBonePadR = res.data.archBonePadR == 1 ? true : false;
          this.report = res.data;

        } else if (res.data == null) {
          this.$message({ message: '该订单还未填写鞋垫参数', type: 'warning' });
          this.innerDrawer = false;
        }

      }

      this.report = res.data;
    },
    //点击确定分配订单给医生
    async reportMatching() {
      const { data: res } = await this.$http.get('/Order/reportMatching', {
        params: {
          orderId: this.orderId,
          reportId: this.reportId
        }
      })
      if (res.code == 1) {
        this.$message({ message: '匹配成功', type: 'success' })
        this.innerDrawer = false;
        this.getOrderList();
      }
    },
    // 根据报告id获取检查报告
    async getReportByReportId(reportId) {
      this.reportId = reportId;
      this.innerDrawer = true;
      const { data: res } = await this.$http.get('/Report/getReportByReportId', {
        params: {
          reportId: reportId
        }
      })
      this.FootReport = res.data;
    },
    //查看用户检查次数对话框
    async ViewReport(item) {
      this.drawer = true;
      this.orderId = item.id;
      const { data: res } = await this.$http.get('/Report/getReportListByUserId', {
        params: {
          userId: item.userId
        }
      })
      console.log(res);
      this.NumberTimes = res.data;
    },
    //点击放大图片
    EnlargeImage(url) {
      this.reportUrl = url;
      this.Enlarge = true;
    },
    // 展示修改地址的对话框
    showBox(item) {
      this.addressVisible = true;
      this.RefundNote = item.id;
    },

    //修改收货地址
    async ModifyAddress() {
      const { data: res } = await this.$http.get('/Order/modificationAddress', {
        params: {
          id: this.RefundNote,
          consignee: this.addressForm.consignee,
          phone: this.addressForm.phone,
          shippingAddress: this.addressForm.shippingAddress,
        }
      })
      console.log(res);
      if (res.code == 1) {
        this.addressVisible = false;
        this.getOrderList();
        return this.$message.success("修改成功!");
      }
      this.$message.error("修改失败!");
    },

    async LogisticsInformation(item) {
      this.logistics = true;
      this.RefundNote = item.id;
    },
    //获取快递公司列表
    async getCourierCodingList() {
      const { data: res } = await this.$http.get('/CourierCoding/getList', {
        params: {
          page: 1,
          pageSize: 100
        }
      })
      this.CourierList = res.data.records;
    },

    //确定填写快递  //选择快递物品
    async LogisticsConfirmation() {
      const { data: res } = await this.$http.get('/Order/enrollCourierNumber', {
        params: {
          id: this.RefundNote,
          CourierNumber: this.CourierNumber,
          coding: this.coding,
          type: this.type,
        }
      })
      console.log(1111, this.type);
      if (res.code == 1) {
        this.logistics = false;
        this.getOrderList();
        return this.$message.success(res.data);
      }

      this.$message.error(res.msg);

    },
    //退款按钮
    refund(item) {
      console.log(item);
      this.RefundDialogBox = true;
      this.RefundNote = item;
      this.money = item.amount;
    },

    //确认退款
    async refundDisposeByOrderId() {
      const { data: res } = await this.$http.get('/Refund/refundDisposeByOrderId', {
        params: {
          id: this.RefundNote.id,
          administratorRemark: this.administratorRemark,
          money: this.money,
        }
      })
      console.log(res);
      if (res.code == 1) {
        this.RefundDialogBox = false;
        this.getOrderList()
        return this.$message.success(res.data)
      }
      this.RefundDialogBox = false;
      this.$message.error(res.msg);
    },
    //医生扫码连接机器再蓝牙连接打印机，后进行打印
    selectDoctor(item) {
      this.forwardForm.doctorId = item.userId;
    },
    async QueryDoctor() {
      const { data: res } = await this.$http.get('/Doctor/getDoctorByName', {
        params: {
          name: this.QueryDoctorValue
        }
      })
      this.DoctorList = res.data;
      console.log(res);
    },
    //获取订单列表
    async getOrderList() {
      if (this.timeObj) {
        this.queryInfo.startTime = this.timeObj[0];
        this.queryInfo.endTime = this.timeObj[1];
      }
      const { data: res } = await this.$http.get('/Order/getList', {
        params: this.queryInfo
      })
      if (res.code != 1) {
        return this.$message.error(res.msg)
      }
      console.log(res);
      this.total = res.data.total;
      let list = res.data.records;
      list.forEach(item => {
        if (item.reportUrl) {
          item.reportUrlList = JSON.parse(item.reportUrl);
        } else {
          item.reportUrlList = [];
        }
      })
      this.orderlist = list;

      console.log(this.orderlist);
    },
    // 点击确定，分配订单
    async determineForward() {
      // 校验通过 发起分配订单的网络请求
      const { data: res } = await this.$http.get('/Order/orderTransmit', {
        params: this.forwardForm
      })
      // 请求的返回结果
      if (res.code != 1) return this.$message.error(res.msg)
      this.$message.success('分配订单成功')
      this.addDialogVisible = false

    },

    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getOrderList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getOrderList();
    },
    // 点击取消按钮关闭修改地址的对话框后 清空表单数据（确定按钮发送请求等的功能省略了）
    addressDialogClosed() {
      this.$refs.addressFormRef.resetFields()
    },

    // //订单分配给医生
    async showForward(item) {
      this.forwardForm.id = item.id
      this.addDialogVisible = true;
    }
  }
}
</script>

<style lang="less" scoped>
// 导入时间线的样式
@import '../../plugins/timeline/timeline.css';
@import '../../plugins/timeline-item/timeline-item.css';

.el-cascader {
  width: 100%;
}

.DoctorList {
  display: flex;
}

.DoctorItem {
  border: rgb(184, 181, 181) solid 1px;
  margin: 5px 10px;
  padding: 5px 10px;

}

.nameage {
  height: 100px;
  border: #e6e6e6 solid 1px;
  margin: 10px 0px;

  .title {
    height: 20px;
    padding: 10px;
    border-bottom: #e6e6e6 solid 1px;
    font-weight: 600;
  }
}

.images {
  margin: 0 auto;
  border: #e6e6e6 solid 1px;
  width: 100%;
  height: 233px;

  .title {
    height: 20px;
    padding: 10px;
    border-bottom: #e6e6e6 solid 1px;
    font-weight: 600;
  }

  .grid-content {
    text-align: center;
    width: 95%;
  }
}

.FootData {
  margin: 0 auto;
  border: #e6e6e6 solid 1px;
  width: 100%;
  margin: 10px 0px;

  .title {
    height: 20px;
    padding: 10px;
    border-bottom: #e6e6e6 solid 1px;
    font-weight: 600;
  }

  .FootData-text {
    line-height: 33px;
    font-family: fantasy;
    padding: 12px;
  }
}
</style>
