<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>退款列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入内容" v-model="queryInfo.query" clearable>
            <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <!-- <el-button type="primary" @click="addDialogVisible = true">添加设备</el-button> -->
          <!-- 点击后跳转到路由/goods/add还是这个页面位置显示 -->
        </el-col>
      </el-row>

      <!-- table表格区域 -->
      <el-table :data="goodslist" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="商品名称" prop="commodityName" width="300px"></el-table-column>
        <el-table-column label="收货人姓名" prop="consignee"></el-table-column>
        <el-table-column label="处理人留言" prop="administratorRemark"></el-table-column>
        <el-table-column label="付款金额" prop="amount"></el-table-column>
        <el-table-column label="订单id" prop="id"></el-table-column>
        <el-table-column label="联系电话" prop="phone"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="购买商品规格" prop="productColore"></el-table-column>
        <el-table-column label="处理状态" prop="disposeState">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.disposeState == '1'">已退款</el-tag>
            <el-tag type="danger" v-else>{{ scope.row.disposeState == '0'?"未处理":"拒绝退款"}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="退款原因" prop="userRemark"></el-table-column>
        <el-table-column label="处理时间" prop="processingTime"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope"> <!-- 作用域插槽 -->
            <!-- 确认退款按钮 -->
            <el-button type="primary" icon="el-icon-check" size="mini" @click="RefundConfirmation(scope.row.id,1)"></el-button>
            <!-- 删除按钮 -->
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="RefundConfirmation(scope.row.id,2)"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--退款确认对话框 -->
      <el-dialog title="退款信息确认" :visible.sync="addDialogVisible" width="40%">
        <el-form :model="addForm" ref="addFormRef" label-width="50px">

          <div v-if="RefundState==2">管理员留言：<el-input
  type="textarea"
  :rows="2"
  placeholder="请输入内容"
  v-model="administratorRemark">
</el-input></div>
          <div v-else>退款金额：<el-input v-model="money" placeholder="请输入内容"></el-input></div>
       
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="determineForward">确 定</el-button>
        </span>

      </el-dialog>


      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>

    </el-card>
  </div>
</template>
  
<script>
import { regionData, CodeToText } from "element-china-area-data";
export default {
  data() {
    return {
      id:"",
      RefundState:'',
      // 查询参数对象
      queryInfo: {
        query: '',
        page: 1,
        pageSize: 10,
        state: 1
      },
      administratorRemark: '',
      money: 0,

      addForm:{

      },
      addDialogVisible: false,
      // 商品列表
      goodslist: [],
      // 总数据条数
      total: 0,
    }
  },
  created() {
    this.getUserList()
  },
  methods: {
    //确认退款
    async determineForward(){
      const { data: res } = await this.$http.get('/Refund/refundDispose' ,{
              params:{
                id:this.id,
                administratorRemark:this.administratorRemark,
                money:this.money,
                state:this.RefundState,
              }
            })
            console.log(res);
            if(res.code ==1){
              this.getUserList()
              this.addDialogVisible = false;
              return this.$message.success(res.data)
            }
            this.$message.error(res.msg);
    },
    
    async RefundConfirmation(id,state) {
      this.RefundState=state;
      this.id = id
      this.addDialogVisible = true;
    },
    // ----------拒绝退款订单
    async removeUserById(id) {

    },
    // 根据分页获取对应的退款订单
    async getUserList() {
      const { data: res } = await this.$http.get('/Refund/getRefundList', {
        params: this.queryInfo
      })

      if (res.code != 1) {
        return this.$message.error('获取退款列表失败！')
      }
      this.$message.success('获取退款列表成功！')
      console.log(res.data)
      this.goodslist = res.data.records
      this.total = res.data.total
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getGoodsList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getGoodsList()
    },
    async removeById(id) {
      const confirmResult = await this.$confirm(
        '此操作将永久删除退款订单, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)

      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }

      const { data: res } = await this.$http.get(``, {
        params: { id: id }
      })

      if (res.code != 1) {
        return this.$message.error('删除失败！')
      }

      this.$message.success('删除成功！')
      this.getGoodsList()
    },

  }
}
</script>
  
<style lang="less" scoped></style>
  