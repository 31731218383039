<template>
    <div class="w">
        <!-- layout栅格 设置多少行多少列 span是宽度 gutter是间隙 -->
        <el-row :gutter="3" style="margin-bottom: 30px;">
            <el-col :span="7">
                <div class="block">
                    <span class="demonstration">检查时间：</span>
                    <el-date-picker v-model="timeObj" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" @change="changeDate">
                    </el-date-picker>
                </div>
            </el-col>
            <el-col :span="4">
                <div class="block">
                    <span class="demonstration">学校：</span>
                    <el-select v-model="queryData.schoolId" placeholder="请选择" clearable @change="changeSchool">
                        <el-option v-for="item in shoolList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
            <el-col :span="4">
                <div class="block">
                    <span class="demonstration">年级：</span>
                    <el-select v-model="queryData.gradeId" placeholder="请选择" clearable @change="changeGrade">
                        <el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
            <el-col :span="4">
                <div class="block">
                    <span class="demonstration">班级：</span>
                    <el-select v-model="queryData.classId" placeholder="请选择" clearable >
                        <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
            <el-col :span="2"><el-button type="primary" @click="refreshStatistics()">开始统计</el-button></el-col>

        </el-row>
        <div>
            <div style="display: flex;">
                <div class="ecMain">
                    <div>
                    </div>
                    <div id="GradeBox" style="width: 920px;height:600px;"></div>
                </div>
                <div class="ecMain">
                    <div>
                    </div>
                    <div id="ArchAreaIndexBox" style="width: 920px;height:600px;"></div>
                </div>

            </div>
            <div>
                <div class="ecMain">
                    <div style="display: flex;">
                    </div>
                    <div id="WidthIndexBox" style="width: 920px;height:600px;"></div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import * as echarts from 'echarts';


export default {
    data() {
        return {
            timeObj: null,
            shoolList: [],
            gradeList: [],
            classList: [],

            schoolName: "",
            gradeName: "",
            className: "",
            queryData: {
                schoolId: null,
                gradeId: null,
                classId: null,
                startTime: "",
                endTime: "",
                sex: '2',
            }
        }
    },
    mounted() {
        this.getFootLengthStatistics();
        this.getArchAreaIndexStatistics();
        this.getShoolList();
        this.getGradeList();

        this.getWidthIndexStatistics();
    },
    methods: {
        changeSchool(e) {
            this.classList = [];

            let obj = this.shoolList.filter(item => item.id === e);

            this.schoolName = obj[0].name;

        },
        changeGrade(e) {
            let obj = this.gradeList.filter(item => item.id === e);

            this.gradeName = obj[0].name;

            this.$http.get('/ClassGrade/getList', {
                params: {
                    gradeId: this.queryData.gradeId,
                    schoolId: this.queryData.schoolId,
                }
            }).then((res) => {
                if (res.data.code != 1) {
                    return
                }
                this.classList = res.data.data

            })
        },
        
        classGrade(e) {
            let obj = this.classList.filter(item => item.id === e);

this.className = obj[0].name;
        },
        refreshStatistics(v) {
            this.getArchAreaIndexStatistics();
            this.getFootLengthStatistics();
            this.getWidthIndexStatistics();
        },
        getFootLengthStatistics() {
            let that = this;
            this.$http.get('/statistics/getFootLengthStatistics', {
                params: this.queryData
            }).then((res) => {
                if (res.data.code != 1) {
                    return
                }
                let arr = res.data.data;


                let xAxisData = [];
                let totalQuantity = [];
                let maleQuantity = [];
                let femaleQuantity = [];
                arr.forEach((v) => {
                    xAxisData.push(v.feetLength);
                    totalQuantity.push(v.totalQuantity);
                    maleQuantity.push(v.maleQuantity);
                    femaleQuantity.push(v.femaleQuantity
                    );
                })
                let name1 = this.schoolName ? (this.schoolName + " ") : "";
                let name2 = this.gradeName ? (this.gradeName + " ") : "";
                let name3 = this.className ? (this.className + " ") : "";
                let name = name1 + name2 + name3;
                name = name ? (name+" 脚长统计图") : "全部学生脚长统计"
                that.init(name, "GradeBox", xAxisData, totalQuantity, maleQuantity, femaleQuantity);
            })
        },
        getArchAreaIndexStatistics() {
            let that = this;
            this.$http.get('/statistics/getArchAreaIndexStatistics', {
                params: this.queryData
            }).then((res) => {
                if (res.data.code != 1) {
                    return
                }
                let arr = res.data.data;


                let xAxisData = [];
                let totalQuantity = [];
                let maleQuantity = [];
                let femaleQuantity = [];
                arr.forEach((v) => {
                    xAxisData.push(v.archAreaIndex);
                    totalQuantity.push(v.totalQuantity);
                    maleQuantity.push(v.maleQuantity);
                    femaleQuantity.push(v.femaleQuantity);
                })
                this.init("足印面积系数分析", "ArchAreaIndexBox", xAxisData, totalQuantity, maleQuantity, femaleQuantity);

            })
        },

        getWidthIndexStatistics() {
            let that = this;
            this.$http.get('/statistics/getWidthIndexStatistics', {
                params: this.queryData
            }).then((res) => {
                if (res.data.code != 1) {
                    return
                }
                let arr = res.data.data;

                let xAxisData = [];
                let totalQuantity = [];
                let maleQuantity = [];
                let femaleQuantity = [];
                arr.forEach((v) => {
                    xAxisData.push(v.archWidthIndex);
                    totalQuantity.push(v.totalQuantity);
                    maleQuantity.push(v.maleQuantity);
                    femaleQuantity.push(v.femaleQuantity);
                })
                this.init("足宽面积系数分析", "WidthIndexBox", xAxisData, totalQuantity, maleQuantity, femaleQuantity);

            })
        },

        init(name, boxName, xAxisData, totalQuantity, maleQuantity, femaleQuantity) {
    let chartDom = document.getElementById(boxName);
    let myChart = echarts.init(chartDom);
    let option;

    option = {
        title: {
            text: name
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['全部', '男', '女']
        },
        grid: {
            left: '22',
            right: '2%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxisData,
            axisLine: {
                lineStyle: {
                    color: 'auto' // 这里可以根据需要设置颜色
                }
            },
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dashed',
                    color: 'auto' // 这里可以根据需要设置颜色
                }
            },
            axisTick: {
                show: false
            }
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                name: '全部',
                type: 'line',
                stack: 'Total',
                data: totalQuantity
            },
            {
                name: '男',
                type: 'line',
                stack: 'Total',
                data: maleQuantity
            },
            {
                name: '女',
                type: 'line',
                stack: 'Total',
                data: femaleQuantity
            }
        ]
    };

    // 根据图表名称设置不同的颜色区间
    if (name === '足印面积系数分析') {
        option.xAxis.splitLine.lineStyle.color = [
            ['0', '0.21', '#FFA07A'],
            ['0.21', '0.26', '#90EE90'],
            ['0.26', '0.3', '#FFFF99'],
            ['0.3', 'Infinity', '#FF0000']
        ];
    } else if (name === '足宽面积系数分析') {
        option.xAxis.splitLine.lineStyle.color = [
            ['2', '3', '#8B0000'],
            ['0.33', '0.5', '#DAA520'],
            ['0.5', '0.66', '#FFFF00'],
            ['0.66', '1.57', '#008000']
        ];
    }

    myChart.setOption(option);

    // 监听 legend 点击事件
    myChart.on('legendselectchanged', function (event) {
        let selected = event.selected;
        let seriesName = Object.keys(selected)[0];
        let newOption = {
            series: option.series.map(item => {
                if (item.name === seriesName) {
                    item.selected = selected[seriesName];
                }
                return item;
            })
        };
        myChart.setOption(newOption);
    });
},



        changeDate(e) {
            this.queryData.startTime = e[0];
            this.queryData.endTime = e[1];
        },
        //获取学校列表
        async getShoolList() {
            this.$http.get("/Report/getSchoolList").then((res) => {

                let data = res.data;
                if (data.code == 1) {
                    this.shoolList = data.data;
                }

            })

        },
        //获取年级列表
        async getGradeList() {
            this.$http.get("/Grade/getList").then((res) => {

                let data = res.data;
                if (data.code == 1) {
                    this.gradeList = data.data;
                }

            })

        },
    }
}
</script>

<style lang="less" scoped>
.ecMain {
    border: 4px solid #ccc;
    width: 920px;
    height: 600px;
    margin: 5px;
    border-radius: 20px;
}
</style>