<template>
    <div class="w">
        <div class="image-btn">
            <div class="image-input" >
                <el-input ref="myInput" v-model="stuUniId" placeholder="请输入内容" clearable style="width: 400px;"
                    @input="handleInput"></el-input>
            </div>
          <div>
            <el-button type="primary" icon="el-icon-success" @click="startCamera">开启设备</el-button>
            <el-button type="info" icon="el-icon-error" @click="stopCamera">关闭设备</el-button>
            <el-button type="info" v-if="this.videoStream" icon="el-icon-error" @click="clickMasterFrame">{{ masterFrame==1?'关闭原始画面':'开启原始画面' }}</el-button>
          </div>
        </div>
        <video class="image-video" ref="video" width="1280" height="960" autoplay style="display: none;"></video>
        <div class="main_box">
            <div class="current_box">
                <div class="stu_data">
                    <div>
                        姓名：{{ name || '' }}
                    </div>
                    <div>
                        性别：{{ sex == '' ? '' : sex == 'f' ? '男' : '女' }}
                    </div>
                    <div>
                        年龄：{{ age || '' }}
                    </div>
                </div>
                <div class="boot_prompt_box"  v-if="!this.videoStream" style="font-size: 40px;">请开启设备</div>
                <div> <canvas class="image-canvas" ref="canvasProcessed" width="1280" height="960"></canvas>
                </div>
                <div class="result_box" >
                    <div style="margin-right: 30px;">
                        <img v-if="leftImage"  :src="leftImage" />
                        <div v-if="leftFoot != ''">左脚：{{ leftFoot || "" }}</div>

                        <div>{{ `${leftFoot != '请站好' && leftFootAccuracy ? '精度:' + leftFootAccuracy : ''}` }}</div>
                    </div>
                    <div>
                        <img v-if="rightImage" :src="rightImage" />
                        <div v-if="rightFoot != ''">右脚：{{ rightFoot || "" }}</div>
                        <div>{{ `${rightFoot != '请站好' && rightFootAccuracy ? '精度:' + rightFootAccuracy : ''}` }}</div>
                    </div>
                </div>
                <el-button style="width: 640px;" type="success" icon="el-icon-camera"
                    @click="manualShooting"  v-if="this.videoStream">采集数据</el-button>
            </div>
            <div class="previous_box">
                <div class="stu_data">
                    <div>
                        姓名：{{ personalData.name }}
                    </div>
                    <div>
                        性别：{{  personalData.sex == '' ? '' :  personalData.sex == 'f' ? '男' : '女' }}
                    </div>
                    <div>
                        年龄：{{ personalData.age }}
                    </div>
                </div>
                <div class="img_box">
                    <img v-if="personalData.leftImage" class="leftImg" :src="`data:image/jpeg;base64,${personalData.leftImage}`" />
                    <img v-if="personalData.rightImage" :src="`data:image/jpeg;base64,${personalData.rightImage}`" />
                </div>
                <div class="result_box">
                    <div style="margin-right: 30px;">
                        <div v-if="personalData.leftFoot != ''">左脚：{{ personalData.leftFoot || "" }}</div>
                        <div v-if="personalData.leftFoot != '请站好' && personalData.leftFootAccuracy">精度：{{
                            personalData.leftFootAccuracy || "" }}</div>
                    </div>
                    <div>
                        <div v-if="personalData.rightFoot != ''">右脚：{{ personalData.rightFoot || "" }}</div>
                        <div v-if="personalData.rightFoot != '请站好' && personalData.rightFootAccuracy">精度：{{
                            personalData.rightFootAccuracy || "" }}</div>
                    </div>
                </div>
            </div>
        </div>
<div class="master_frame_box" v-show="masterFrame==1"> 
    <div>
        <div>实时原画</div>
        <canvas class="image-canvas" ref="canvasOriginal" width="640" height="480"></canvas>
    </div>
    <div>
        <div>原图</div>   
         <img ref="photo" alt="拍摄的照片" style="display: none;" /></div>
</div>
    </div>
</template>

<script>

//   import  debounce from "@/utls/debounce.js"
export default {
    data() {
        return {
            debounceTimer: null,
            masterFrame:0,
            videoStream: null,
            stuUniId: '',
            leftFoot: "",
            rightFoot: "",
            leftFootAccuracy: "",
            rightFootAccuracy: "",
            leftImage: "",
            rightImage: "",
            age: '',
            id: "",
            name: "",
            sex: "",
            targetColors: [
                [189, 226, 172], [177, 255, 193], [180, 255, 199], [190, 250, 255],
                [160, 222, 171], [180, 242, 201], [175, 255, 230], [159, 214, 156],
                [166, 216, 178],
            ],
            tolerance: 35,
            captureInterval: null, // 用于存储定时器
            includeImage: 0,    //是否需要图片
            personalData: {
                name: "",
                sex: "",
                age: "",
                className: "",
                gradeName: "",
                leftImage: "",
                rightImage: "",
                leftFoot: "",
                rightFoot: "",
                leftFootAccuracy: "",
                rightFootAccuracy: "",
            }
        };
    },
    mounted() {
        console.log(333);

        this.$refs.myInput.focus();
    },
    methods: {
        clickMasterFrame(){
     this.masterFrame=this.masterFrame==1?0:1
        },
        handleInput() {
            let that = this;
if(!that.stuUniId){
    return 
}
            clearTimeout(that.debounceTimer);
            that.debounceTimer = setTimeout(() => {
                that.getUserDate();
            }, 500); // 600ms 延迟
        },

        getUserDate() {
            this.$http.get('user/getDecode', {
                params: {
                    uniId: this.stuUniId
                }
            }).then((res) => {
                console.log(res);
                let data = res.data;
                if (data.code != 1) {
                    return
                }

                this.id = data.data.id;
                this.name = data.data.name;
                this.sex = data.data.sex;
                this.age = data.data.age;
                console.log(this.name);

            })
        },
        async startCamera() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                this.videoStream = stream;
                this.$refs.video.srcObject = stream;
                this.startCapturing();
                this.$refs.video.onloadedmetadata = () => {
                    requestAnimationFrame(this.processVideo);

                };
            } catch (error) {
                console.error('Error accessing camera:', error);
            }
        },
        manualShooting() {
      
            let that = this;
            if((that.leftFoot=='请站好'||that.leftFoot=='系统错误等会再试'||that.leftFoot=='')&&(that.rightFoot=='请站好'||that.rightFoot=='系统错误等会再试'||that.rightFoot=='')){
                this.$message.error('未识别到脚型不能提交');
                return
            }

            clearInterval(this.captureInterval);
            this.capturePhoto(1);

            setTimeout(() => {
                that.startCapturing();
            }, 1500);
            this.stuUniId = "";
            this.$refs.myInput.focus();

            const video = this.$refs.video;
            const canvasOriginal = this.$refs.canvasOriginal;
            const photo = this.$refs.photo;

            if (!canvasOriginal || !photo) {
                return;
            }

            canvasOriginal.width = video.videoWidth;
            canvasOriginal.height = video.videoHeight;
            const context = canvasOriginal.getContext('2d');
            context.drawImage(video, 0, 0, canvasOriginal.width, canvasOriginal.height);

            // 将拍摄的照片显示在 img 元素中
            photo.src = canvasOriginal.toDataURL('image/jpeg');
            photo.style.display = 'block'; // 确保 img 元素是可见的

            // 创建一个临时链接，用于下载照片
            const downloadLink = document.createElement('a');
            downloadLink.href = canvasOriginal.toDataURL('image/jpeg');
            downloadLink.download = this.name + "_" + new Date().getTime() + '.jpeg'; // 设置下载的文件名
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        },
        stopCamera() {
            if (this.videoStream) {
                this.videoStream.getTracks().forEach(track => track.stop());
                this.videoStream = null;
                this.$refs.video.srcObject = null;
            }
            this.stopCapturing();
        },
        startCapturing() {
            clearInterval(this.captureInterval);
            this.captureInterval = setInterval(this.capturePhoto, 1500);  // 每秒拍摄一次
        },
        stopCapturing() {
            if (this.captureInterval) {
                clearInterval(this.captureInterval);  // 清除定时器
                this.captureInterval = null;
            }
        },
        capturePhoto(includeImage = 0) {
            const canvas = this.$refs.canvasProcessed;
            if (!canvas) {

                return;
            }
            const context = canvas.getContext('2d');
            const video = this.$refs.video;

            if (video.videoWidth === 0 || video.videoHeight === 0) {
                console.error('Video dimensions are not set.');
                return;
            }

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            const imageData = context.getImageData(0, 0, video.videoWidth, video.videoHeight);
            const data = imageData.data;

            for (let i = 0; i < data.length; i += 4) {
                let r = data[i];
                let g = data[i + 1];
                let b = data[i + 2];
                let found = this.targetColors.some(([targetR, targetG, targetB]) => {
                    return (
                        Math.abs(r - targetR) <= this.tolerance &&
                        Math.abs(g - targetG) <= this.tolerance &&
                        Math.abs(b - targetB) <= this.tolerance
                    );
                });

                if (!found) {
                    data[i] = 0;     // Red channel
                    data[i + 1] = 0; // Green channel
                    data[i + 2] = 0; // Blue channel
                }
            }

            context.putImageData(imageData, 0, 0);
            canvas.toBlob((blob) => {
                if (blob) {
                    this.uploadImage(blob, includeImage);
                }
            }, 'image/jpeg');
        },

        uploadImage(blob, includeImage) {
            let that = this;
            const formData = new FormData();
            let name = this.name + "_" +this.id+"_"+ new Date().getTime();
            formData.append('file', blob, `${name}.jpeg`);
            formData.append('includeImage', includeImage);

            // fetch('http://127.0.0.1:5000/analyze', {
            fetch('https://eyetest.cc/aiAnalysis/analyze', {
                method: 'POST',
                body: formData,
            })
     
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    
                    const regex = /^(.*?) \(Confidence:\s*([\d.]+)\)$/;
                    const leftArr = data.leftFoot.match(regex);
                    that.leftFoot = leftArr[1];
                    that.leftFootAccuracy = leftArr[2];


                    const rightArr = data.rightFoot.match(regex);
                    that.rightFoot = rightArr[1];
                    that.rightFootAccuracy = rightArr[2];


                    if (data.leftImage) {
                        this.personalData.leftImage =   data.leftImage;
                    }
                    if (data.rightImage) {
                        this.personalData.rightImage = data.rightImage;
                    }

                    if (includeImage == 1) {

                        that.personalData.leftFoot = leftArr[1];
                        that.personalData.leftFootAccuracy = leftArr[2];
                        that.personalData.rightFoot = rightArr[1];
                        that.personalData.rightFootAccuracy = rightArr[2];
                        that.personalData.id = this.id;
                        that.personalData.name = this.name;
                        that.personalData.sex = this.sex;
                        that.personalData.age = this.age;

                        //    保存结果
                        that.save(leftArr[1], rightArr[1]);

                    }




                })
                .catch((error) => {
                    that.leftFoot = "系统错误等会再试";
                    that.rightFoot = "系统错误等会再试";
                    console.error('Error:', error);
                });


        },
        save(leftFoot, rightFoot) {


            let obj = {
                archTypeL: leftFoot,
                archTypeR: rightFoot,
                userId: this.id,
                imgR: this.personalData.rightImage,
                imgL:this.personalData.leftImage
            }
            this.$http.post('/AgileInstrument/save', obj).then((res) => {
                console.log(res);

                if (res.data.code == 1) {
                    this.includeImage = 0;

                } else {
                    this.$message.error('保存失败');
                }
            })
        },
        processVideo() {
            const video = this.$refs.video;
            const canvasOriginal = this.$refs.canvasOriginal;
            const canvasProcessed = this.$refs.canvasProcessed;
            const contextOriginal = canvasOriginal.getContext('2d');
            const contextProcessed = canvasProcessed.getContext('2d');

            if (video.videoWidth === 0 || video.videoHeight === 0) {

                return;
            }

            canvasOriginal.width = video.videoWidth;
            canvasOriginal.height = video.videoHeight;
            canvasProcessed.width = video.videoWidth;
            canvasProcessed.height = video.videoHeight;

            contextOriginal.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

            // 应用高斯模糊
            const imageDataOriginal = contextOriginal.getImageData(0, 0, video.videoWidth, video.videoHeight);
            contextProcessed.putImageData(imageDataOriginal, 0, 0);
            contextProcessed.filter = 'blur(1px)'; // 这里的5px是模糊半径，可以调整
            contextProcessed.drawImage(canvasOriginal, 0, 0, video.videoWidth, video.videoHeight);
            const imageDataProcessed = contextProcessed.getImageData(0, 0, video.videoWidth, video.videoHeight);

            // 处理颜色过滤
            const data = imageDataProcessed.data;
            for (let i = 0; i < data.length; i += 4) {
                let r = data[i];
                let g = data[i + 1];
                let b = data[i + 2];
                let found = this.targetColors.some(([targetR, targetG, targetB]) => {
                    return (
                        Math.abs(r - targetR) <= this.tolerance &&
                        Math.abs(g - targetG) <= this.tolerance &&
                        Math.abs(b - targetB) <= this.tolerance
                    );
                });

                if (!found) {
                    data[i] = 0;     // Red channel
                    data[i + 1] = 0; // Green channel
                    data[i + 2] = 0; // Blue channel
                }
            }

            contextProcessed.putImageData(imageDataProcessed, 0, 0);
            requestAnimationFrame(this.processVideo);
        },
  
    }
};
</script>

<style lang="less">
.image-btn{
    display: flex;
    .image-input{
        margin-right: 80px;
    }
}
.image-video {
    display: none;
}

.main_box {
    display: flex;

}
.boot_prompt_box{
    font-size: 40px;
    color: rgb(49, 49, 49);
    text-align: center;
    margin-top: 100px;
}
.image-canvas {
    display: block;
    width: 640px;
    height: 480px;
}

.result_box {
    width: 640px;
    display: flex;
    >div {
    flex: content;
    font-size: 32px;
    text-align: center;
}

}



.current_box {
    border: 4px dashed #ccc;
    padding: 10px;
}

.previous_box {
    border: 4px dashed #ccc;
    padding: 10px;
    margin-left: 40px;
    width: 700px;
    height: 650px;

    .img_box {
        >img {
            height: 480px;
            margin: 0px 30px 20px 30px;

        }
        .leftImg{
    transform: scaleX(-1);
}
    }
}

.stu_data {
    display: flex;
    width: 700px;
    height: 30px;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;

    >div {
        flex: 1;
        text-align: center;
    }
}
.master_frame_box{
    margin-top: 40px;
    display: flex;
 
    >div{
        text-align: center;
        width: 700px;
        padding: 10px;
        border: 4px dashed #ccc;
    }
    >div:nth-child(2){
        margin-left: 40px;
    }


}
</style>